import React, { useState } from 'react';
import { Button, Container } from '@ui';
import { Box, media, pxRem, Text } from '@sanch941/lib';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import bgMain from '@assets/images/welcome/main_bg_desktop.webp';
import bgMainMobile from '@assets/images/welcome/main_bg_mobile.webp';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import styled, { css } from 'styled-components';

export const WelcomeTemplate = () => {
    return (
        <StyledContainer>
            <Box pTop={120} pBottom={482} md={{ pTop: 246, pBottom: 504 }}>
                <Container>
                    <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        animateOnce={true}
                        delay={200}
                        offset={0}
                    >
                        <Box $bottom={16}>
                            <Text
                                fz={16}
                                lh={18}
                                fw={300}
                                ta="center"
                                color="#6B6B6B"
                                md={{ fz: 28, lh: 32, ta: 'right' }}
                            >
                                Рост вашего бизнеса — наша цель
                            </Text>
                        </Box>
                    </AnimationOnScroll>
                    <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        animateOnce={true}
                        delay={400}
                        offset={0}
                    >
                        <Box>
                            <StyledTitle
                                fz={24}
                                lh={32}
                                fw={500}
                                ta="center"
                                color="#000"
                                md={{ fz: 48, lh: '132%', ta: 'right' }}
                            >
                                Быстрая и безопасная <br /> обработка онлайн
                                платежей
                            </StyledTitle>
                        </Box>
                    </AnimationOnScroll>
                    <Box $flex jc="center" $top={32} md={{ jc: 'flex-end' }}>
                        <Box $width={200} md={{ $width: 441 }}>
                            <StyledButton as={AnchorLink} to="/#connection">
                                Подключить
                            </StyledButton>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </StyledContainer>
    );
};

const StyledTitle = styled(Text)``;

const StyledContainer = styled(Box)`
    background-image: url('${bgMainMobile}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    background-color: #111111;

    ${media.md} {
        background-image: url('${bgMain}');
    }
`;

const StyledButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${pxRem(40)};
    background-color: #7d2dea;
    border-radius: 4px;
    font-weight: 500;
    font-size: ${pxRem(18)};
    line-height: ${pxRem(20)};
    letter-spacing: 0.04em;
    color: #ffffff;

    ${media.md} {
        font-size: ${pxRem(32)};
        height: ${pxRem(68)};
    }
`;
