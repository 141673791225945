import React, { FC, useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';

export const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
};

export const defaultStyle = {
    transition: `opacity 300ms ease-in-out`,
    opacity: 0
};

export const Initialization: FC = ({ children }) => {
    const [fakeDuration, setFakeDuration] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setFakeDuration(true);
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Transition in={fakeDuration} timeout={200} mountOnEnter>
            {(state) => (
                <StyledContainer
                    style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                    }}
                >
                    {children}
                </StyledContainer>
            )}
        </Transition>
    );
};

const StyledContainer = styled.div`
    height: 100vh;
    width: auto;
    background-color: #111111;
`;
