import React, { useEffect, useState } from 'react';
import { Box, media, Text } from '@sanch941/lib';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import InputMask from 'react-input-mask';
import { Form, Formik } from 'formik';
import { initialValues, sendPhone, SendPhoneSchema } from './model';
import { Button, Container, Loader } from '@ui';
import { pxRem } from '@lib';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@store';
import { setCookie } from '@features/cookie';

export const PciDssTemplate = () => {
    const dispatch = useDispatch();

    const status = useAppSelector(({ email }) => email.sendMailLoading);

    const isLoading = status === 'pending';

    const isSuccess = status === 'fulfilled';

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_content',
            'utm_term'
        ].forEach((utm) => {
            const utmValue = searchParams.get(utm);
            if (utmValue && utmValue.trim() !== '') {
                setCookie(utm, utmValue, {
                    expires: new Date(
                        new Date().getTime() + 7 * 24 * 60 * 60 * 1000
                    )
                });
            }
        });
    }, []);

    return (
        <Box bg="#151517" id="connection">
            <Container>
                <Box pTop={50} pBottom={50} md={{ pTop: 32, pBottom: 80 }}>
                    <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        animateOnce={true}
                    >
                        <Text
                            as="h2"
                            $color="#fff"
                            ta="center"
                            tt="uppercase"
                            fz={18}
                            fw={400}
                            md={{ fz: 24, lh: '132%' }}
                        >
                            Давайте обсудим Ваш проект ☕
                        </Text>
                    </AnimationOnScroll>

                    <Box $top={16}>
                        <AnimationOnScroll
                            animateIn="animate__fadeInUp"
                            animateOnce={true}
                        >
                            <StyledTitle
                                fw={400}
                                fz={16}
                                lh="130%"
                                ta="center"
                                $color="#BFBFBF"
                                md={{ fz: 18, lh: 24 }}
                            >
                                Чтобы запросить предложение или встретиться за
                                чашкой кофе, свяжитесь с нами напрямую или
                                заполните форму, и мы свяжемся с вами в
                                ближайшее время
                            </StyledTitle>
                        </AnimationOnScroll>
                    </Box>

                    <Box $top={48} $flex jc="center">
                        <Box md={{ $width: 421 }} $width="100%">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={(values, actions) => {
                                    dispatch(sendPhone({ values, actions }));
                                }}
                                validateOnChange={false}
                                validateOnBlur={false}
                                validationSchema={SendPhoneSchema}
                            >
                                {({ values, errors, handleChange }) => (
                                    <Form>
                                        <Box $bottom={15} md={{ $bottom: 24 }}>
                                            <FloatingLabelInput
                                                unformattedLabel="Ваше имя"
                                                name="name"
                                                type="text"
                                                onChange={handleChange}
                                                value={values.name}
                                                error={errors.name}
                                                disabled={isLoading}
                                            />
                                            {/* <Input
                                            name="name"
                                            placeholder="Имя"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.name}
                                            error={errors.name}
                                            disabled={isLoading}
                                        /> */}
                                        </Box>
                                        <Box $bottom={15} md={{ $bottom: 24 }}>
                                            <InputMask
                                                name="phone"
                                                mask="+7(999) 999 99 99"
                                                disabled={isLoading}
                                                onChange={handleChange}
                                                value={values.phone}
                                            >
                                                {(inputProps) => (
                                                    <FloatingLabelInput
                                                        {...inputProps}
                                                        disableUnderline
                                                        error={errors.phone}
                                                        unformattedLabel="Ваш телефон"
                                                        type="text"
                                                    />
                                                )}
                                            </InputMask>
                                        </Box>
                                        <Box $bottom={15} md={{ $bottom: 24 }}>
                                            <FloatingLabelInput
                                                unformattedLabel="Ваша почта"
                                                name="email"
                                                type="email"
                                                onChange={handleChange}
                                                value={values.email}
                                                error={errors.email}
                                                disabled={isLoading}
                                            />
                                            {/* <Input
                                            name="name"
                                            placeholder="Имя"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.name}
                                            error={errors.name}
                                            disabled={isLoading}
                                        /> */}
                                        </Box>
                                        <Box $bottom={32} md={{ $bottom: 32 }}>
                                            <FloatingLabelInput
                                                unformattedLabel="Комментарий"
                                                name="comment"
                                                type="comment"
                                                onChange={handleChange}
                                                value={values.comment}
                                                error={errors.comment}
                                                disabled={isLoading}
                                            />
                                            {/* <Input
                                            name="name"
                                            placeholder="Имя"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.name}
                                            error={errors.name}
                                            disabled={isLoading}
                                        /> */}
                                        </Box>
                                        {status == 'rejected' && (
                                            <Box $top={32} $bottom={32}>
                                                <Text
                                                    fw={400}
                                                    fz={16}
                                                    lh="130%"
                                                    ta="center"
                                                    $color="#FF4242"
                                                    md={{ fz: 18, lh: 24 }}
                                                >
                                                    Что-то пошло не так,
                                                    пожалуйста, повторите
                                                    попытку
                                                </Text>
                                            </Box>
                                        )}
                                        <StyledButton
                                            type="submit"
                                            // disabled={
                                            //     !dirty ||
                                            //     isSubmitting ||
                                            //     isLoading
                                            // }
                                            isSuccess={isSuccess}
                                            // isLoading={isLoading}
                                        >
                                            {isLoading ? (
                                                <Loader isLoading={isLoading} />
                                            ) : isSuccess ? (
                                                'Заявка успешно отправлена'
                                            ) : (
                                                'Оставить заявку'
                                            )}
                                        </StyledButton>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

// const StyledImg = styled.img`
//     max-height: ${pxRem(100)};
//
//     ${media.md} {
//         max-height: ${pxRem(200)};
//     }
// `;

const StyledTitle = styled(Text)``;

// const Input = styled.input<{ error?: string }>``;

const InputContainer = styled.div`
    position: relative;
`;

const InputLabel = styled.label<{
    focused: any;
    error: any;
    hasValue: boolean;
}>`
    position: absolute;
    top: ${({ focused, hasValue }) => (focused || hasValue ? '-12px' : '12px')};
    font-size: ${({ focused, hasValue }) =>
        focused || hasValue ? '14px' : '18px'};
    font-weight: 400;
    line-height: 20px;
    color: #bfbfbf;
    pointer-events: none;
    transition: 0.2s ease-out;
`;

const InputField = styled.input<{ focused: any; error: any }>`
    width: 100%;
    height: 42px;
    padding: 5px 5px 5px 0;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid ${({ error }) => (error ? '#FF4242' : '#b2bcca')};
    outline: none;
    transition: 0.2s ease-out;
    background-color: transparent;
    color: #fff;

    //noinspection ALL
    &:-internal-autofill-selected {
        background-color: red;
    }
`;

const FloatingLabelInput = (props) => {
    const [focused, setFocused] = useState(false);

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    return (
        <InputContainer>
            <InputLabel
                focused={focused}
                error={props.error}
                hasValue={props.value.length > 0}
            >
                {props.unformattedLabel}
            </InputLabel>
            <InputField
                type="text"
                focused={focused}
                onFocus={handleFocus}
                onBlur={handleBlur}
                {...props}
            />
        </InputContainer>
    );
};

const StyledButton = styled(Button)<{ isSuccess: boolean }>`
    height: 48px;
    background-color: ${({ isSuccess }) => (isSuccess ? '#00B027' : '#7d2dea')};
    transition: all 0.2s ease-out;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-size: 18px;
    line-height: 20px;
    width: 100%;
`;
