import React, { useEffect } from 'react';
import { Box, pxRem, Text } from '@sanch941/lib';
import { Container } from '@ui';
import { Card } from './card';
import { faqActions } from './model';
import { useAppDispatch, useAppSelector } from '@store';
import styled from 'styled-components';
import { AppStateType } from '@store/root-reducer';
import { faqList } from './faq-list';
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const QuestionsTemplate = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(faqActions.setQuestions(faqList));
    }, []);

    const { questions } = useAppSelector(
        ({ faq: { questions } }: AppStateType) => ({
            questions
        })
    );

    return (
        <Box bg="#F9F9F9" id="faq">
            <Container>
                <Box pTop={50} pBottom={80} md={{ pTop: 32, pBottom: 157 }}>
                    <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        animateOnce={true}
                    >
                        <StyledTitle
                            fw={400}
                            fz={18}
                            lh={20}
                            ta="center"
                            tt="uppercase"
                            $color="#000"
                            md={{ fz: 24, lh: '132%' }}
                        >
                            Остались вопросы? 🤔
                        </StyledTitle>
                    </AnimationOnScroll>

                    <Box $top={16}>
                        <AnimationOnScroll
                            animateIn="animate__fadeInUp"
                            animateOnce={true}
                        >
                            <StyledTitle
                                fw={400}
                                fz={16}
                                lh={20}
                                ta="center"
                                $color="#6B6B6B"
                                md={{ fz: 18, lh: 24 }}
                            >
                                Мы предоставляем интернет-эквайринг, быстрые
                                выплаты на банковские карты и моментальные
                                онлайн-переводы через электронный кошелек и
                                мобильную коммерцию.
                            </StyledTitle>
                        </AnimationOnScroll>
                    </Box>

                    <Box $top={32}>
                        <StyledQuestions>
                            {questions.map((question, idx) => (
                                <Card {...question} key={idx} />
                            ))}
                        </StyledQuestions>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

const StyledQuestions = styled.div`
    // padding-top: ${pxRem(10)};
`;

const StyledTitle = styled(Text)``;
