import React, { useState } from 'react';
import { Box, pxRem, Collapsible, media, Text } from '@sanch941/lib';
import styled, { css } from 'styled-components';
import logo from '@assets/images/logo.png';
import { navigationMenuList } from '@lib';
import { useLocation } from '@reach/router';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import phone from '@assets/images/header/phone.svg';
import email from '@assets/images/header/email.svg';

import burger from '@assets/images/header/burger.svg';
import union from '@assets/images/header/union.svg';

import { Button } from '@ui/atoms/button';

export const Mobile = () => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <StyledContainer>
                <Collapsible
                    open={open}
                    $onClick={() => setOpen((prev) => !prev)}
                    VisiblePart={<VisiblePart open={open} />}
                    Inner={<Inner setOpen={setOpen} />}
                />
            </StyledContainer>
        </>
    );
};

const VisiblePart = ({ open }) => (
    <Box $flex jc="space-between" ai="center">
        <StyledLogo src={logo} alt="logo" />
        {open ? (
            <img src={union} alt="burger" />
        ) : (
            <img src={burger} alt="union" />
        )}
    </Box>
);

const StyledContainer = styled.div`
    margin: 0 ${pxRem(16)};
    padding: ${pxRem(16)} ${pxRem(20)};
    position: relative;
    background: #ffffff;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
`;

const StyledLogo = styled.img`
    max-height: ${pxRem(23)};
`;

const Inner = ({ setOpen }) => {
    const { pathname } = useLocation();
    return (
        <Box>
            <Box $top={32}>
                <Box $flex fxd="column" ai="center">
                    {navigationMenuList.map(({ text, to, ...props }, idx) => (
                        <Box
                            key={idx}
                            $bottom={16}
                            onClick={() => setOpen(false)}
                        >
                            <StyledLink
                                as={AnchorLink}
                                $active={pathname === to}
                                to={to}
                                {...props}
                            >
                                {text}
                            </StyledLink>
                        </Box>
                    ))}
                    <Box $bottom={16} onClick={() => setOpen(false)}>
                        <StyledLink
                            as="a"
                            href="https://developer.maxpay.kz/"
                            target="_blank"
                        >
                            Разработчикам
                        </StyledLink>
                    </Box>
                </Box>
            </Box>
            <Box
                $width="100%"
                color="#6B6B6B"
                bd="1px solid #6B6B6B"
                $top={31}
            />
            <Box $top={47}>
                <Box $flex jc="center" $top={24} ai="center">
                    <StyledIcon src={phone} alt="phone" />
                    <StyledLink as="a" href="tel: +7 727 310 24 02">
                        +7 727 310 24 02
                    </StyledLink>
                </Box>
            </Box>
            <Box $flex jc="center" $top={12} ai="center">
                <StyledIcon src={email} alt="email" />
                <StyledLink as="a" href="mailto: info@maxpay.kz">
                    info@maxpay.kz
                </StyledLink>
            </Box>
            <Box $top={24} $flex jc="center">
                <Box
                    $width={200}
                    md={{ $width: 246 }}
                    onClick={() => setOpen(false)}
                >
                    <StyledButton as={AnchorLink} to="/#connection">
                        Связаться с нами
                    </StyledButton>
                </Box>
            </Box>
            <Box $top={50} $flex jc="center">
                <Text $color="#6B6B6B" fz={14} fw={500} lh={20} ta="center">
                    050021, Казахстан, г. Алматы, пр. Достык 97, <br /> офис 8
                </Text>
            </Box>
        </Box>
    );
};

const StyledButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${pxRem(40)};
    background-color: #7d2dea;
    border-radius: 4px;
    font-weight: 500;
    font-size: ${pxRem(18)};
    line-height: ${pxRem(20)};
    letter-spacing: 0.04em;
    color: #ffffff;
`;

const StyledLink = styled.a<{ $active?: boolean }>`
    font-weight: 400;
    font-size: ${pxRem(16)};
    line-height: ${pxRem(23)};
    color: #000;
    position: relative;
    transition: color 0.3s ease-in-out;

    ${({ $active }) =>
        $active &&
        css`
            font-weight: 500;
            color: #7c2cea;
        `}
    &:hover {
        color: #7c2cea;
    }
`;

const StyledIcon = styled.img`
    max-height: ${pxRem(24)};

    ${media.md} {
        max-height: ${pxRem(32)};
    }
`;
