import React from 'react';
import { Box, Text, pxRem } from '@sanch941/lib';
import styled from 'styled-components';

export const RadioButton = ({ label, value, onChange }) => {
    return (
        <Box $flex ai="center">
            <RadioButtonNative
                type="radio"
                checked={value}
                onChange={onChange}
            />
            <RadioButtonLabel>{label}</RadioButtonLabel>
        </Box>
    );
};

const RadioButtonLabel = styled(Text).attrs({
    fz: 16,
    lh: 24,
    $color: '#404040',
    as: 'label'
})`
    display: block;
    margin-left: ${pxRem(8)};
`;

const RadioButtonNative = styled.input`
    width: ${pxRem(20)};
    height: ${pxRem(20)};
    background: #ffffff;
    border: 1px solid #c2c2c2;
    box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.08);
`;
